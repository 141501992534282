import request from '@/utils/request'
import { getToken } from "@/utils/auth"

//获取仓库信息
export function getList(query) {
  return request({
    url: '/erp/erpDepot/erplist',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
	params: query
  })
}

//获取转仓单
export function AllList(query) {
  return request({
    url: '/erp/allotorder/erplist',
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
	  params: query
  })
}

//获取子转仓单
export function getSonLists() {
  return request({
    url: '/erp/allotorder/allList' ,
    headers: {"Authorization": "Bearer  " + getToken()},
    method: 'get',
  })
}

// 新增转仓单
export function addAllotorder(data) {
  return request({
    url: '/erp/allotorder/erp',
	headers: {"Authorization": "Bearer  " + getToken()},
    method: 'post',
    data: data
  })
}

// 修改转仓单
export function updateAllotorder(data) {
  return request({
    url: '/erp/allotorder/erp',
	headers: {"Authorization": "Bearer  " + getToken()},
    method: 'put',
    data: data
  })
}

// 删除调拨订单
export function delAllotorder(allotId) {
  return request({
    url: '/erp/allotorder/erp/' + allotId,
	headers: {"Authorization": "Bearer  " + getToken()},
    method: 'delete'
  })
}


//获取商品SKU
export function getson() {
  return request({
    url:'/erp/SkuSon/erplist',
    headers: {
      "Authorization": "Bearer  " + getToken()
    },
    method: 'get' 
  })
}

// 查询仓库资料depot列表
export function listDepot(query) {
  return request({
    url: '/wms/depot/erpList',
	headers: {
	  "Authorization": "Bearer  " + getToken()
	},
    method: 'get',
    params: query
  })
}

//获取物流公司 wms/logistics/list
export function getLogistics() {
  return request({
    url: 'wms/logistics/list',
	headers: {
	  "Authorization": "Bearer  " + getToken()
	},
    method: 'get',
  })
}